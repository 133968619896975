import React from 'react';
import Title from './Sections/Title';
import WhatWeDo from './Sections/WhatWeDo';
import OurTenets from './Sections/OurTenets';
import GetInvolved from './Sections/GetInvolved';
import LearnMore from './Sections/LearnMore'; // Import the LearnMore component

const Landing = () => {
  return (
    <div className="landing-container">
      <Title />
      <WhatWeDo />
      <OurTenets />
      <GetInvolved />
      
      {/* Include the LearnMore component below GetInvolved */}
      <LearnMore />
      
      {/* Other content of the Landing page */}
      {/* ... */}
    </div>
  );
};

export default Landing;
