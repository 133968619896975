import React from 'react';
import './CoreResearchAreas.css'; // Update the import to match your new CSS file name

function CoreResearchAreas() {
  return (
    <div className="core-research-areas"> {/* Updated class name here */}
      <h1>Core Research Areas</h1>
      <p>
        1. Feature Engineering: Our feature engineering process is methodical and technical. It involves selecting, transforming, and creating variables from raw data to optimize machine learning models. We identify relevant attributes, handle missing data, encode categorical variables, and engineer new features to enhance model performance. This process is about preparing data in a structured format that allows algorithms to extract meaningful patterns efficiently, without unnecessary embellishments.
        <br />
        <br />
        2. Intangible Analysis: Our approach centers on the intricate relationship between human performance and environmental factors. We delve into data to reveal subtle, often overlooked relationships crucial for insights. Understanding these minor influences is key to our investment strategy, as they often lead to significant outcomes.
        <br />
        <br />
        3. Predictive Modelling: Our predictive models utilize AI and machine learning for enhanced forecast accuracy. We focus on algorithm selection, fine-tuning, and historical data analysis. Key aspects include rigorous model validation, hyperparameter optimization, and ensemble learning, complemented by real-time data integration for dynamic model adaptation to the evolving sports and esports realms.
        <br />
        <br />
        4. Market Arbitrage: We specialize in identifying discrepancies in betting odds across various platforms for Market Arbitrage. Our analysis involves comparing odds from different bookmakers and exchanges to find profitable variations. Our strategy is to exploit these odds inconsistencies to develop effective betting strategies.
      </p>
    </div>
  );
}

export default CoreResearchAreas;
