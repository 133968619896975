import React from 'react';
import './ProjectPage.css';
import projectImage from '../../../../assets/match_predictor_code.jpg';

const LoLMatchPredictor = () => {
  return (
    <div className="projectpage-background">
      <h1 className="projectpage-title">League Of Legends Match Predictor:</h1>
      <p className="projectpage-centered-text">
        We researched the feasibility of predicting match outcomes in League of Legends esports by implementing a diverse range of features and applying logistic regression to forecast the binary result of a victory or defeat.
      </p>
      <img
        src={projectImage}
        alt="Project Image"
        className="projectpage-image"
      />
      <p className="projectpage-left-bound-text">
        <strong>Methodology:</strong>
        <br />
        <br />
        <strong>Data Pipeline:</strong> Establishing a consistent data pipeline was crucial. Utilizing the Google Drive API, we accessed real-time professional game data. This approach underscores the project's impact by harnessing LoL's superior data infrastructure, which surpasses traditional sports in data availability and granularity.
        <br />
        <br />
        <strong>Model Development:</strong> Initial attempts with pre-draft models were less fruitful, leading to our recommendation against their use for reliable betting predictions. We then shifted focus to post-draft features, leveraging the online nature of LoL and its advanced data capabilities. Our unique, proprietary post-draft features resulted in a highly effective logistic regression model.
        <br />
        <br />
        <strong>Model Accuracy and Deployment:</strong> The logistic regression model, performing binary classification, achieved an impressive 92% accuracy in predicting wins and losses. This success demonstrates the significant impact of our research, offering valuable tools for the large audience of esports enthusiasts and bettors. For deployment, we employed tools like Joblib and MLRun, ensuring efficient application to match predictions.
        <br />
        <br />
        <strong>Future Research and Prospects:</strong> The promising results pave the way for future research, particularly in assessing the model's profitability and long-term efficacy. Our findings suggest a clear path for enhancing the model's capabilities, potentially leading to more accurate and profitable betting strategies in the esports arena. This aspect of our research contributes significantly to the evolving field of data-driven sports analytics.
        <br />
        <br />
        <strong>Team Members:</strong>
        <ul>
          <li>
            <strong>Lead Data Scientist:</strong> <a href="https://www.linkedin.com/in/micahmathews/" className="projectpage-linkedin-link" target="_blank" rel="noopener noreferrer">
              <u>Micah Mathews</u>
            </a>
          </li>
          <li>
            <strong>Associate Data Scientist Team:</strong>
          </li>
        </ul>
      </p>
    </div>
  );
};

export default LoLMatchPredictor;
