import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Landing from './pages/Landing/Landing';
import GetInvolved from './pages/GetInvolved/Getinvolved';
import ResearchInitiatives from './pages/ResearchInitiatives/ResearchInitiatives';
import LoLMatchPredictor from './pages/ResearchInitiatives/Sections/Projects/LoLMatchPredictor';
import HomeFieldAdvantage from './pages/ResearchInitiatives/Sections/Projects/HomeFieldAnalysis';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/get-involved" element={<GetInvolved />} />
          <Route path="/research-initiatives/lol-match-predictor" element={<LoLMatchPredictor />} />
          <Route path="/research-initiatives/home-field-advantage" element={<HomeFieldAdvantage />} />
          <Route path="/research-initiatives" element={<ResearchInitiatives />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;


