import React from 'react';
import './LearnMore.css';

const LearnMore = () => {
  const handleClick = () => {
    window.location.href = '/get-involved';
  };

  return (
    <div className="learn-more-container" onClick={handleClick}>
      {/* Image tag removed, using CSS background instead */}
      <p className="learn-more-text">Check Out Our Current Opportunities</p>
    </div>
  );
};

export default LearnMore;
