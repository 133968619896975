import React from 'react';
import Title2 from './Sections/Title2';
import WhatsAhead from './Sections/WhatsAhead.js';
import OurOpportunities from './Sections/Opportunities.js';

const GetInvolved = () => {
    return (
      <div className="landing-container">
        <Title2 />
        <WhatsAhead/>
        <OurOpportunities/>


      </div>
    );
  };
  
  export default GetInvolved;
  