import React from 'react';
import './WhatsAhead.css';

function WhatsAhead() {
  return (
    <div className="what-we-do">
      <h1>Whats Ahead</h1>
      <p>
        At The Wagered Family, our immediate strategy is centered around
        harnessing data-driven insights for sports and esports forecasting. Key
        aspects include:
        <br />
        <br />
        1. Data Exploration in Varied Domains: Venturing into different sports and
        esports arenas to accumulate diverse data, essential for our predictive
        analytics.
        <br />
        <br />
        2. Predictive Model Development & Testing: Crafting and fine-tuning
        specialized models to analyze player performance and team dynamics,
        ensuring accuracy in predictions.
        <br />
        <br />
        3. Financial Simulation Integration: Implementing a financial bankroll risk
        and investment manager simulation, to merge finance best practices into
        our forecasting methods.
        <br />
        <br />
        4. Odds Web Scraping Initiative: Undertaking a project to scrape betting
        odds across various sites, aiming to create profitable betting
        strategies through market discrepancies.
      </p>
    </div>
  );
}

export default WhatsAhead;
