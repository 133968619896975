import React, { useState } from 'react';
import './OurTenets.css';

const tenets = [
    { title: 'Professional Development', description: 'As a student led organization, we understand the critical role that professional development plays in your journey. Our Professional Development program is designed to equip you with the skills and knowledge needed to excel in your future career.' },
    { title: 'Predictive Analytics', description: 'Explore the world of data-driven insights with our Predictive Analytics program. Discover how to leverage cutting-edge techniques to forecast outcomes in sports and esports, gaining a competitive edge in the betting arena.' },
    { title: 'Research and Development', description: 'Our Research and Development initiative is at the forefront of innovation in the betting industry. Join us in exploring new strategies and tools, ensuring that you stay ahead of the curve in this rapidly evolving landscape.' },
    { title: 'Education and Awareness', description: 'Responsible gambling is paramount. We\'re committed to raising awareness about the potential risks and promoting responsible choices.' },
    { title: 'Community Engagement', description: 'Connect with a community of like-minded individuals who share your passion for data-driven betting. Engage in forums and events that provide networking opportunities and foster a collaborative learning environment.' },
    { title: 'Risk Management', description: 'We take risk management seriously. Learn how to protect your investments and maintain a balanced approach to betting with our Risk Management strategies. Gain insights into minimizing potential losses while maximizing your betting experience.' },
    { title: 'Ethical Betting', description: 'At the core of our mission is ethical betting. We advocate for transparency, fairness, and responsible play within the industry. In an environment where bookmakers can sometimes be prone to corruption, we are dedicated to shedding light on these issues.' }
  ];
  

  function OurTenets() {
    const [hoveredTenet, setHoveredTenet] = useState(null);

    // Removed img tag for BasketballImage since we are setting it as a background now
  
    return (
        <div className="our-tenets">
            {/* img tag removed */}
            <h1 className="tenets-title">Our Tenets</h1>
            <div className="tenets-container">
                {tenets.map((tenet, index) => (
                    <div 
                        key={index} 
                        className={`tenet ${hoveredTenet === index ? 'hovered' : ''}`}
                        onMouseEnter={() => setHoveredTenet(index)}
                        onMouseLeave={() => setHoveredTenet(null)}
                    >
                        <div className="tenet-title">{tenet.title}</div>
                        {hoveredTenet === index && <div className="tenet-description">{tenet.description}</div>}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OurTenets;
