import React from 'react';
import './Footer.css'; 

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <p className="section-title">Connect:</p>
        <a href="https://www.linkedin.com/company/wagered/" className="social-link" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a href="https://www.instagram.com/wagered.tech/" className="social-link" target="_blank" rel="noopener noreferrer">Instagram</a>
      </div>
      <div className="footer-section">
        <div className="centered-content">
          <p className="section-title">Contact:</p>
          <p>ucsdwagered@gmail.com</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
