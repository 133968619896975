import React from 'react';
import './Title.css';
import wageredImage from '../../../assets/landing_img.png';

const Title = () => {
  return (
    <div className="title-container">
      <img src={wageredImage} alt="Wagered" className="title-image" />
      <div className="text-overlay">
        <div className="title-text">Wagered</div>
        <div className="subtitle-text">
        Leveraging quantitative analysis to create sustainable betting success.
        </div>
      </div>
    </div>
  );
};

export default Title;