import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Projects.css';
import LoLMatchPredictionImage from '../../../assets/lol-match-prediction.jpg';
import ProjectsBackgroundImage from '../../../assets/project.jpg';
import HomeFieldAdvantage from '../../../assets/home_field_adv.jpg';

const projects = [
    {
        title: 'League of Legends Match Predictor',
        description: 'Conducting research on the feasibility of predicting match outcomes in League of Legends esports by implementing a diverse range of features and applying logistic regression to forecast the binary result of a victory or defeat.',
        domain: 'lol-match-predictor',
        newDomain: 'lol-match-predictor',
        backgroundImage: LoLMatchPredictionImage
    },
    {
        title: 'Home Field Advantage Quantification',
        description: 'Quantifying the impact of homefield advantage for NBA teams in order to determine how much these factors should be accounted for in investment.',
        domain: 'home-field-advantage',
        backgroundImage: HomeFieldAdvantage
    },
];

function Projects() {
    const [hoveredProject, setHoveredProject] = useState(null);
    const navigate = useNavigate();

    const handleProjectClick = (projectDomain, newDomain) => {
        navigate(newDomain || projectDomain);
    };

    return (
        <div className="projects" style={{ backgroundImage: `url(${ProjectsBackgroundImage})` }}>
            <h1 className="projects-title">Projects</h1>
            <div className="projects-container">
                {projects.map((project, index) => (
                    <div key={index} className="project-container">
                        <div 
                            className={`project ${hoveredProject === index ? 'hovered' : ''}`}
                            onMouseEnter={() => setHoveredProject(index)}
                            onMouseLeave={() => setHoveredProject(null)}
                            onClick={() => handleProjectClick(project.domain, project.newDomain)}
                            style={{ backgroundImage: `url(${project.backgroundImage})` }}
                        >
                            <div className="project-title">{project.title}</div>
                            {hoveredProject === index && <div className="project-description">{project.description}</div>}
                        </div>
                        <p 
                            className="learn-more" 
                            onClick={() => handleProjectClick(project.domain, project.newDomain)}
                        >
                            Learn More
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Projects;