import React from 'react';
import './GetInvolved.css'; // Make sure to import your CSS file

function GetInvolved() {
  return (
    <div className="get-involved-do"> {/* Updated class name to match CSS */}
      <h1>Interested In Joining Our Mission?</h1> {/* Updated the heading text */}
      <p>
        {/* Updated the paragraph text */}
        Wagered is currently looking for UCSD students to join our research endeavors. We have a range of technical roles available to support our overarching mission of testing the feasibility of statistical gambling across various sports and esports. If you're a UCSD student and passionate about exploring the world of sports analytics and betting, we welcome your participation.
      </p>
      <p>
        Recruitment for the Winter 2024 positions is targeted to start in under a month, so keep an eye out for our upcoming announcements!
      </p>
    </div>
  );
}

export default GetInvolved;

