import React from 'react';
import './WhatWeDo.css';

function WhatWeDo() {
  return (
    <div className="what-we-do">
      <h1>What We Do</h1>
      <p>Wagered is a pioneering organization dedicated to leveraging advanced quantitative tools for the purpose of crafting sophisticated predictive analyses in the domains of sports and esports. Our core methodology revolves around the meticulous application of data-driven strategies, enabling us to place well-informed wagers based on quantifiable outcomes. This approach not only fosters a substantial return on investments but also underscores our commitment to promoting a more conscientious and responsible gambling paradigm. Recognizing the historically detrimental impact of gambling on numerous lives, Wagered seeks to redefine the gambling experience, blending analytical rigor with a mindful approach to mitigate the associated risks and enhance the overall integrity of betting practices.</p>
    </div>
  );
}

export default WhatWeDo;