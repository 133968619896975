import React from 'react';
import './Title2.css';
import GetInvolved from '../../../assets/get_involved.png';

const Title2 = () => {
  return (
    <div className="title-container">
      <img src={GetInvolved} alt="Wagered" className="title-image" />
      <div className="text-overlay">
        <div className="title-text">Join The Wagered Family</div>
        <div className="subtitle-text">
        Become a pioneer in the new frontier of data-driven sports and esports forecasting.
        </div>
      </div>
    </div>
  );
};

export default Title2;