import React, { useState } from 'react';
import './Opportunities.css';

const opportunities = [
    { title: 'Front-End Development Lead', description: 'The Front-End lead will be tasked with utilizing React to enhance our website, showcasing our research initiatives dynamically. They will also be responsible for establishing a collaborative infrastructure, enabling multiple team members to contribute to the website\'s development remotely instead of working exclusively on local environments.', link: 'https://forms.gle/JmxAQeND41Rz45zg7' },
    { title: 'Predictive Model Development Lead', description: 'Predictive Model Development Lead will foster seamless collaboration among team members, conduct performance evaluations, and facilitate code reviews within individual projects. Furthermore, they will collaborate closely with our front-end development team, integrating predictive models into our website, making data-driven insights accessible to our audience.', link: 'https://forms.gle/u4xLgX3omFCdt64C6' },
    { title: 'Arbitrage System Development Lead', description: 'Arbitrage System Development Lead', description: 'Arbitrage System Development Lead will lead the project in the development of our web scraping initiative, seeking live odds from various betting sites to uncover discrepancies and financial opportunities. Their responsibilities include project leadership, overseeing technical development for real-time odds data retrieval, efficient data collection, identification of odds disparities, continuous system performance monitoring, and comprehensive documentation', link: 'https://forms.gle/ZQTfUptcYUTGCbkn9' },
    { title: 'Risk and Strategy Lead', description: 'Risk and Strategy Lead will also be responsible for implementing robust risk management strategies to safeguard our resources, ensuring that calculated risks align with predefined risk tolerance levels. They will formulate and execute investment strategies that aim to maximize returns while minimizing potential losses, all within the context of responsible betting practices and oversee bankroll management. This role is also responsible for educating our team about the general practice of safe and strategic investing in the gambling world and broader finance.', link: 'https://forms.gle/hPZtgYp7U7U7u2jP7' },
];

function OurOpportunities() {
    const [hoveredOpportunity, setHoveredOpportunity] = useState(null);

    const handleOpportunityClick = (link) => {
        window.open(link, '_blank');
    };

    return (
        <div className="our-opportunities">
            <h1 className="opportunities-title">Opportunities</h1>
            <div className="opportunities-container">
                {opportunities.map((opportunity, index) => (
                    <div 
                        key={index} 
                        className="opportunity-container"
                        onClick={() => handleOpportunityClick(opportunity.link)} // Move the click handler here
                    >
                        <div 
                            className={`opportunity ${hoveredOpportunity === index ? 'hovered' : ''}`}
                            onMouseEnter={() => setHoveredOpportunity(index)}
                            onMouseLeave={() => setHoveredOpportunity(null)}
                        >
                            <div className="opportunity-title">{opportunity.title}</div>
                            {hoveredOpportunity === index && 
                                <div className="opportunity-description">{opportunity.description}</div>}
                        </div>
                        <p className="learn-more">
                            Apply
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OurOpportunities;