import React from 'react';
import './ProjectPage.css';
import projectImage from '../../../../assets/home_field_adv.jpg';

const HomeFieldAdvantage = () => {
  return (
    <div className="projectpage-background">
      <h1 className="projectpage-title">Home Field Advantage Quantifier:</h1>
      <p className="projectpage-centered-text">
        We Quantify the impact of homefield advantage for NBA teams in order to determine how much these factors should be accounted for in investment.
      </p>
      <img
        src={projectImage}
        alt="Project Image"
        className="projectpage-image"
      />
      <p className="projectpage-left-bound-text">
        <strong>Methodology:</strong>
        <br />
        <br />
        The concept of home field advantage in the NBA is a subject that fascinates fans and analysts. This project delved into quantifying this phenomenon, with a special focus on the unique "Mile High Advantage" of the Denver Nuggets. Recognizing that home field advantage is influenced by various factors – from altitude and fan enthusiasm to familiar surroundings – we sought to create a comprehensive metric to measure its impact.
        <br />
        <br />
        <strong>Data Collection:</strong> Using the NBA API, we gathered a substantial dataset, covering team records throughout the millennium. This rich dataset, comprising thousands of games, provided a robust foundation for our analysis.
        <br />
        <br />
        <strong>Metric Development:</strong> Our goal was to develop a metric that quantified the likelihood of a team winning at home against an equally matched opponent playing away. The analysis revealed that while all teams perform better at home, the degree of this advantage varies. Notably, teams like Utah and Denver, playing in high-altitude arenas, often exhibited a significant home advantage, though not always the highest.
        <br />
        <br />
        <strong>Deeper Analysis:</strong> Further analysis beyond what was presented in the graph indicated that the underlying factors contributing to a strong home court advantage for other teams remain elusive. Our investigation into variables such as team strength, city size, average travel distance, and fan base size showed little correlation with the extent of home court advantage.
        <br />
        <br />
        <strong>Conclusion for Betting Purposes:</strong> This project's findings offer intriguing insights for betting strategies. While it's clear that home field advantage plays a significant role in game outcomes, the varying degrees of this advantage across different teams highlight the complexity of using this factor as a standalone betting criterion. Bettors should consider the multi-faceted nature of home field advantage, especially in cases where factors like altitude play a distinct role. Additionally, the lack of clear correlations with other potential factors suggests that bettors need to employ a nuanced, comprehensive approach when incorporating home field advantage into their betting strategies. This research contributes to a more informed and sophisticated understanding of how home court dynamics influence game outcomes in the NBA.
        <br />
        <br />
        <strong>Team Members:</strong>
        <ul>
          <li>
            <strong>Lead Data Scientist:</strong> <a href="https://www.linkedin.com/in/micahmathews/" className="projectpage-linkedin-link" target="_blank" rel="noopener noreferrer">
              <u>Micah Mathews</u>
            </a>
          </li>
          <li>
            <strong>Associate Data Scientist Team:</strong>
          </li>
        </ul>
      </p>
    </div>
  );
};

export default HomeFieldAdvantage;

