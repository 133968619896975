import React from 'react';
import './Header.css';
import logo from '../../assets/logo_horizontal.png';

const Header = () => {
  return (
    <nav className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <ul className="nav-list">
        <li className="nav-item">
          <a href="/">Home</a>
        </li>
        <li className="nav-item">
          <a href="/get-involved">Get Involved</a>
        </li>
        <li className="nav-item">
          <a href="/research-initiatives">Research Initiatives</a>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
