import React from 'react';
import Title3 from './Sections/Title3';
import CoreResearchAreas from './Sections/CoreResearchAreas';
import Projects from './Sections/Projects';

const ResearchInitiatives = () => {
    return (
      <div className="landing-container">
        <Title3 />
        <CoreResearchAreas />
        <Projects />
      </div>
    );
  };
  
  export default ResearchInitiatives;
  