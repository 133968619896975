import React from 'react';
import './Title3.css';
import GetInvolved from '../../../assets/Title3.png';

const Title3 = () => {
  return (
    <div className="title-container">
      <img src={GetInvolved} alt="Wagered" className="title-image" />
      <div className="text-overlay">
        <div className="title-text">Discover Our Recent Work</div>
        <div className="subtitle-text">
        Explore our recent research projects and findings to see what we've uncovered.
        </div>
      </div>
    </div>
  );
};

export default Title3;